import React from "react"
import { LinkButton } from "gatsby-interface"

const TagGroup = ({ tags, excludeCount }) => (
  <>
    {tags &&
      tags
        .filter(({ count, name }) => name && (excludeCount || count))
        .map(({ slug, count, name }) => {
          const label = excludeCount ? name : `${name} (${count})`
          return (
            <LinkButton
              to={`/blog/tags/${slug}`}
              css={{
                textTransform: "capitalize",
                flexShrink: 0,
              }}
              key={slug}
              variant="SECONDARY"
              size="S"
            >
              {label}
            </LinkButton>
          )
        })}
  </>
)

export default TagGroup
